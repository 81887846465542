export default {
    fullname: "Atsadet Khwanchun",
    nickname: "@tourdecode",
    birthyear: 1995,
    tel: "",
    line: "@atsadet_tour",
    mail: "atsadet.work@gmail.com",
    county: "Thailand",
    address: "Bangkok",
    freelance: "Available",
    about: "I’m working as a style planner and web developer. I’m graduated in software development and develop websites for many firms and government agencies for over the past 6 years, I have gained experiences via types of websites.",
    images: "/images/profile/wewebplus-tour-05457-960x960.jpg",
    no_images: "/images/profile/wewebplus-tour-05457-960x960.jpg",
    social: {
        facebook: "https://facebook.com/TourAtsadet",
        linkedin: "https://www.linkedin.com/in/atsadet-khwanchun-179621154/",
        github: "https://github.com/tourdecode",
    },
    work:[
        "Web Developer",
        "Mobile application Developer",
        "FullStack Developer",
        "Research and Development",
    ],
    certificates: [
        {
            title: "Certificate ITPE<br>IP",
            category: "สวทช.",
            images: [
                "/images/profile/itpe1.jpg",
                "/images/profile/itpe2.jpg",
            ],
        },
    ],
    resumeworks: [
        {
            title: "MNRE",
            images: "/images/works-tour/mnre.png",
            url: "http://www.mnre.go.th",
        },
        {
            title: "DMCR",
            images: "/images/works-tour/dmcr.png",
            url: "https://www.dmcr.go.th",
        },
        {
            title: "MR DMCR",
            images: "/images/works-tour/mrdmcr.png",
            url: "https://mr.dmcr.go.th",
        },
        {
            title: "BDN",
            images: "/images/works-tour/bdn.png",
            url: "https://bdn.go.th",
        },
        {
            title: "DGR",
            images: "/images/works-tour/dgr.png",
            url: "http://www.dgr.go.th",
        },
        {
            title: "NBTC",
            images: "/images/works-tour/nbtc.png",
            url: "",
        },
        {
            title: "NEDA",
            images: "/images/works-tour/neda.png",
            url: "https://www.neda.or.th",
        },
        {
            title: "Scotch",
            images: "/images/works-tour/scotch.png",
            url: "http://scotchthailand.co.th",
        },
        {
            title: "Betagro",
            images: "/images/works-tour/betagro.png",
            url: "",
        },
        {
            title: "Portochino",
            images: "/images/works-tour/portochino.png",
            url: "http://www.portochino.com",
        },
        {
            title: "Unimit",
            images: "/images/works-tour/unimit.png",
            url: "https://www.unimit.com",
        },
        {
            title: "PROPERTYGURU ASIA REAL ESTATE SUMMIT",
            images: "/images/works-tour/asiaproperty.png",
            url: "https://www.asiarealestatesummit.com",
        },
        {
            title: "DLAND",
            images: "/images/works-tour/dland.png",
            url: "https://dl.co.th",
        },
        {
            title: "EJ ERIC Chula",
            images: "/images/works-tour/eric.png",
            url: "http://www.ej.eric.chula.ac.th/home",
        },
        {
            title: "GREEN BOOK DMSC Application",
            images: "/images/works-tour/greenbook.png",
            url: "",
        },
        {
            title: "Lady Monsters",
            images: "/images/works-tour/lady-monster.png",
            url: "https://ladymonsters.in.th",
        },
        {
            title: "Chumphon",
            images: "/images/works-tour/chumphon.png",
            url: "https://www.chumphon.go.th/2021",
        },
        {
            title: "Intelligent",
            images: "/images/works-tour/intelligent.png",
            url: "https://www.intelligent.co.th",
        },
        {
            title: "PBX Sangoma",
            images: "/images/works-tour/pbxsangoma.png",
            url: "https://www.pbxsangoma.com",
        },
        {
            title: "Third500",
            images: "/images/works-tour/third500.png",
            url: "https://third500.com",
        },
        {
            title: "Bangkokpvc",
            images: "/images/works-tour/bangkokpvc.png",
            url: "https://www.bangkokpvc.com",
        },
        {
            title: "Aimautomation",
            images: "/images/works-tour/aim.png",
            url: "https://aimautomation.co.th",
        },
        {
            title: "Teraosan",
            images: "/images/works-tour/terao.png",
            url: "https://www.teraosan.com/",
        },
        {
            title: "Unionwealth",
            images: "/images/works-tour/unionwealth.png",
            url: "https://uwealth.co.th/dopa",
        },
        {
            title: "Cotto coupon",
            images: "/images/works-tour/cotto.png",
            url: "https://www.cotto.com/fulfill-your-dream/concept",
        },
    ],
    awards: {
        fullname: "Atsadet Khwanchun",
        images: "/images/profile/wewebplus-tour-05971-172x172.jpg",
        work: "FullStack Developer",
        desc: "All awards received since college.",
        list: [
            {
                title: "04 September 2016",
                desc: "",
            },
        ],
    },
    resume: {
        experiences: [
            {
                isActive: true,
                year: "2021 - Present",
                subject: "Web Developer",
                title: "Likesoft Co,. Ltd.",
                desc: "",
            },
            {
                isActive: true,
                year: "2019 - Present",
                subject: "CTO & Developer",
                title: "Decodethailand Team.",
                desc: "Working as a freelancer about web development and mobile application.",
            },
            {
                isActive: true,
                year: "2017 - 2021",
                subject: "Web, mobile application developer / Research and development.",
                title: "Wewebplus Co,. Ltd.",
                desc: "",
            },
        ],
        educations: [
            {
                isActive: false,
                year: "2013 - 2017",
                subject: "Chiang Rai Rajabhat University",
                title: "Chiang Rai, Thailand",
                desc: "Bachelor of Science Program in Computer Science.",
            },
        ],
    },
    skills: [
        {
            title: "The Ability to Communicate",
            scale: "70%",
        },
        {
            title: "Always seeking new knowledge",
            scale: "90%",
        },
        {
            title: "Reasoning and Problem Solving",
            scale: "85%",
        },
        {
            title: "Enthusiasm for work",
            scale: "75%",
        },
    ],
    languages: [
        {
            title: "Thai",
            scale: "90%", //10-100
        },
        {
            title: "English",
            scale: "30%",
        },
    ],
    knowledges: [
        "Adaptability",
        "Strong Research Skills",
        "The Ability to Get Focused",
        "Communicate",
        "The Ability to Meet Deadlines",
        "Organizational Skills",
        "Always seeking new knowledge",
    ],
}